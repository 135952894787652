// eslint-disable-next-line no-unused-vars
import React from 'react';
// eslint-disable-next-line no-unused-vars
import './Footer.css';
import { iTextWrapper } from './App.js';
const language = window.navigator.language || window.navigator.userLanguage;
const langKey = language ? language.split('-')[0] : 'de';

const Footer = (props) => {
  return (
    <div className={`footer-container ${props.currentView === 1 ? 'second-page': 'first-page'} view-${props.currentView}`}>
      <div className="footer">
        <a href={langKey === 'de' ? 'https://www.planta.de/impressum/' : 'https://www.planta.de/en/company-details/'} target='_blank' rel='noopener noreferrer'>{iTextWrapper('legalNotice')}</a>
        <a href={langKey === 'de' ? 'https://www.planta.de/kontakt/' : 'https://www.planta.de/en/contact/'} target='_blank' rel='noopener noreferrer'>{iTextWrapper('contact')}</a>
      </div>
      {props.currentView === 1 && <img src="https://ct.capterra.com/capterra_tracker.gif?vid=2127123&vkey=663a8483f1bf99d1dbd8677dc6b781ed" />}
    </div>
  );
};

export default Footer;
