// eslint-disable-next-line no-unused-vars
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';

const MailIcon = () => {
  return (
    <div>
      <FontAwesomeIcon icon={faEnvelopeOpenText} />
    </div>
  );
};

export default MailIcon;
