import './index.css';
import { createRoot } from 'react-dom/client';
/* eslint-disable no-unused-vars */
import React from 'react';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
/* eslint-enable no-unused-vars */

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className="header-main-bar">
      <a target="_blank" rel="noopener noreferrer" title="https://www.planta.de" href="https://www.planta.de">
        <div id="header-logo"></div>
      </a>
    </div>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);
